export const CRUSH_IN_TIME_LEVEL = "CRUSH_IN_TIME_LEVEL"
export const MOVEMENT_TO_POINT_LEVEL = "MOVEMENT_TO_POINT_LEVEL"
export const VARIANT_CANDY_CRUSH_IN_MOVEMENT = "VARIANT_CANDY_CRUSH_IN_MOVEMENT"
export const SPECIALITY_MATCH = "SPECIALITY_MATCH"

export default [
    CRUSH_IN_TIME_LEVEL,
    MOVEMENT_TO_POINT_LEVEL,
    VARIANT_CANDY_CRUSH_IN_MOVEMENT,
    SPECIALITY_MATCH
];