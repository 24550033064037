<template>
  <div>
    <visual-content-view v-if="contentType===VISUAL_CONTENT"
                         @no-content="onNoContent"
                         @close:visual-content="onCloseVisualContent"></visual-content-view>
    <question-content-view v-if="contentType===QUESTION_CONTENT"
                           @quiz:success="onQuizSuccess" @quiz:fail="onQuizFail"
                           @no-content="onNoContent"></question-content-view>
  </div>
</template>

<script>
import contentTypes from "@/game/data/contentTypes";
import {VISUAL_CONTENT, QUESTION_CONTENT} from "@/game/data/contentTypes";
import VisualContentView from "@/game/views/VisualContentView";
import QuestionContentView from "@/game/views/QuestionContentView";

export default {
  name: "LevelFailureGameStateView",
  props: {},
  components: {
    VisualContentView,
    QuestionContentView
  },
  data: () => ({
    contentTypes,
    contentType: null,
    VISUAL_CONTENT,
    QUESTION_CONTENT,
    contentTypeIndex: -1,
    contentSwitchCount: 1
  }),
  methods: {
    initialize() {
      this.contentTypeIndex = this.getRandomIndex(2)
      this.contentType = this.contentTypes[this.contentTypeIndex];
    },

    getRandomIndex(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },

    onQuizSuccess(gameRightRules = []) {
      this.$emit("done:quiz", this.getMovementGameRight(gameRightRules))
    },

    onQuizFail(gameRightRules = []) {
      let gameRight = -1;
      // todo
      this.$emit("done:quiz", gameRight)
    },

    onNoContent() {
      if (this.contentSwitchCount < 2) {
        this.contentType = this.contentTypes[1 - this.contentTypeIndex];
        this.contentSwitchCount++;
      } else {
        this.$emit("close");
      }
    },
    getMovementGameRight(gameRightRules){
      let gameRight = -1;
      let gameMovementRight = gameRightRules.find(gameRightRule => gameRightRule.gameRightUnit === "MOVEMENT" )
      if(gameMovementRight.gameRightQuantityToBeWon > 0) gameRight = gameMovementRight.gameRightQuantityToBeWon;
      return gameRight;
    },
    onCloseVisualContent(gameRightRules) {
      this.$emit("close",this.getMovementGameRight(gameRightRules));
    }
  },
  created() {
  },
  beforeDestroy() {
  },
  mounted() {
    this.initialize();
  }
}
</script>

<style scoped>

</style>